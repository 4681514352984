exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-contatti-spettacoli-js": () => import("./../../../src/pages/contatti/spettacoli.js" /* webpackChunkName: "component---src-pages-contatti-spettacoli-js" */),
  "component---src-pages-degustazioni-index-js": () => import("./../../../src/pages/degustazioni/index.js" /* webpackChunkName: "component---src-pages-degustazioni-index-js" */),
  "component---src-pages-eventi-index-js": () => import("./../../../src/pages/eventi/index.js" /* webpackChunkName: "component---src-pages-eventi-index-js" */),
  "component---src-pages-gestione-taverna-index-js": () => import("./../../../src/pages/gestione-taverna/index.js" /* webpackChunkName: "component---src-pages-gestione-taverna-index-js" */),
  "component---src-pages-gestione-taverna-taverna-js": () => import("./../../../src/pages/gestione-taverna/taverna.js" /* webpackChunkName: "component---src-pages-gestione-taverna-taverna-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luva-advisor-classifica-js": () => import("./../../../src/pages/luva-advisor/classifica.js" /* webpackChunkName: "component---src-pages-luva-advisor-classifica-js" */),
  "component---src-pages-luva-advisor-index-js": () => import("./../../../src/pages/luva-advisor/index.js" /* webpackChunkName: "component---src-pages-luva-advisor-index-js" */),
  "component---src-pages-mercatini-js": () => import("./../../../src/pages/mercatini.js" /* webpackChunkName: "component---src-pages-mercatini-js" */),
  "component---src-pages-notizie-index-js": () => import("./../../../src/pages/notizie/index.js" /* webpackChunkName: "component---src-pages-notizie-index-js" */),
  "component---src-pages-ristoranti-index-js": () => import("./../../../src/pages/ristoranti/index.js" /* webpackChunkName: "component---src-pages-ristoranti-index-js" */),
  "component---src-pages-taverne-index-js": () => import("./../../../src/pages/taverne/index.js" /* webpackChunkName: "component---src-pages-taverne-index-js" */),
  "component---src-templates-luva-single-evento-js": () => import("./../../../src/templates/luva/single-evento.js" /* webpackChunkName: "component---src-templates-luva-single-evento-js" */),
  "component---src-templates-luva-single-notizia-js": () => import("./../../../src/templates/luva/single-notizia.js" /* webpackChunkName: "component---src-templates-luva-single-notizia-js" */),
  "component---src-templates-luva-single-ristorante-js": () => import("./../../../src/templates/luva/single-ristorante.js" /* webpackChunkName: "component---src-templates-luva-single-ristorante-js" */),
  "component---src-templates-luva-single-taverna-js": () => import("./../../../src/templates/luva/single-taverna.js" /* webpackChunkName: "component---src-templates-luva-single-taverna-js" */)
}

